<template>
  <div>
    <div class="page-label">子账号列表</div>
    <div class="sub-account-list-wrap">
      <b-container fluid>
        <div class="screen-wrap">
          <div class="left-wrap">
            <el-form ref="form" :inline="true" :model="form" label-width="90px">
              <el-form-item label="子账号级别:">
                <el-select v-model="form.subAccountLevel" placeholder="请选择">
                  <el-option
                    v-for="item in subAccountLevelOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="子账号上级:">
                <el-select v-model="form.subAccountSuperior" placeholder="请选择">
                  <el-option
                    v-for="item in subAccountSuperiorOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账号到期:">
                <el-select v-model="form.accountExpiration" placeholder="请选择">
                  <el-option
                    v-for="item in accountExpirationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关键词：">
                <el-input v-model="form.keywords" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="theme">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="right-wrap">
            <el-button class="invite">+邀请注册</el-button>
          </div>
        </div>
        <div class="sub-account-list">
          <div class="tips">
            <span class="icon-pic">
              <img src="@/../public/icon/gift.png" alt="">
            </span>
            <span class="txt">子账号下的每一笔订单，您都可获得分润。</span>
          </div>
          <div class="sub-account-list-inner">
            <table>
              <thead>
                <tr>
                  <th>
                    <el-checkbox v-model="allChecked"></el-checkbox>
                  </th>
                  <th>账户名</th>
                  <th>真实名称</th>
                  <th>地区</th>
                  <th>子账号级别</th>
                  <th>账户可用余额</th>
                  <th>累计付款</th>
                  <th>子账号上级</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in subAccountListArr" :key="index">
                  <td>
                    <el-checkbox v-model="checked"></el-checkbox>
                  </td>
                  <td>
                    <div class="account-item">
                      <span>{{item.accountInfo.account}}</span>
                      <span v-if="item.accountInfo.isPermanent">永久有效</span>
                      <span v-else>
                        <i class="el-icon-alarm-clock"></i>
                        剩<strong>{{item.accountInfo.term}}</strong>天到期
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="item">{{item.name}}</div>
                  </td>
                  <td>
                    <div class="item">{{item.area}}</div>
                  </td>
                  <td>
                    <div class="item">{{item.accountLevel}}</div>
                  </td>
                  <td>
                    <div class="item gray">{{item.availableBalance}} ¥</div>
                  </td>
                  <td>
                    <div class="item gray">{{item.cumulativePayment}}笔</div>
                  </td>
                  <td>
                    <div class="account-item">
                      <span>{{item.parentInfo.parentAccount}}</span>
                      <span>{{item.parentInfo.phone}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="item link" @click="toSubAccountDetail">账户详情</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="paging-wrap">
          <el-button size="mini" class="export-goods" icon="el-icon-download">导出子账号</el-button>
          <div class="paging-con">
            <el-pagination
              background
              layout="prev, pager, next, jumper"
              :pager-count="5"
              prev-text="上一页"
              next-text="下一页"
              :total="100">
            </el-pagination>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data (){
      return {
        form: {
          subAccountLevel: "",
          subAccountSuperior: "",
          accountExpiration: "",
          keywords: ""
        },
        subAccountLevelOptions: [
          {
            label: "高级会员",
            value: "高级会员"
          },
          {
            label: "普通会员",
            value: "普通会员"
          },
          {
            label: "注册用户",
            value: "注册用户"
          }
        ],
        subAccountSuperiorOptions: [
          {
            label: "东方子墨",
            value: "东方子墨"
          },
          {
            label: "南宫离",
            value: "南宫离"
          },
          {
            label: "路人丙",
            value: "路人丙"
          },
          {
            label: "夏老板",
            value: "夏老板"
          },
          {
            label: "水木年华",
            value: "水木年华"
          },
          {
            label: "路人乙",
            value: "路人乙"
          }
        ],
        accountExpirationOptions: [
          {
            label: "15天内到期",
            value: "15天内到期"
          },
          {
            label: "30天内到期",
            value: "30天内到期"
          },
          {
            label: "90天内到期",
            value: "90天内到期"
          }
        ],
        subAccountListArr: [
          {
            accountInfo:{
              account: "13993660160",
              isPermanent: 1,
              term: "永久有效"
            },
            name: "西门幽若",
            area: "北京 - 北京",
            accountLevel: "注册用户",
            availableBalance: "0.00",
            cumulativePayment: "0",
            parentInfo: {
              parentAccount: "东方子墨",
              phone: 13751081888
            }
          },
          {
            accountInfo:{
              account: "13751081328",
              isPermanent: 0,
              term: "174"
            },
            name: "东方子墨",
            area: "上海 - 上海",
            accountLevel: "普通会员",
            availableBalance: "0.00",
            cumulativePayment: "0",
            parentInfo: {
              parentAccount: "",
              phone: ""
            }
          },
          {
            accountInfo:{
              account: "13751081328",
              isPermanent: 0,
              term: "99"
            },
            name: "南宫离",
            area: "广东 - 广州",
            accountLevel: "高级会员",
            availableBalance: "0.00",
            cumulativePayment: "0",
            parentInfo: {
              parentAccount: "",
              phone: ""
            }
          },
          {
            accountInfo:{
              account: "13751081328",
              isPermanent: 1,
              term: "永久有效"
            },
            name: "北堂影月",
            area: "广东 - 深圳",
            accountLevel: "注册用户",
            availableBalance: "0.00",
            cumulativePayment: "0",
            parentInfo: {
              parentAccount: "东方子墨",
              phone: "13751081888"
            }
          }
        ],
        allChecked: false,
        checked: false
      }
    },
    methods: {
      // 跳转到子账号详情
      toSubAccountDetail(){
        dump.link({
          type: 1,
          link: "subAccountDetail"
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/subAccountManagement/subAccountList/subAccountList";
</style>
